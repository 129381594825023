
import {
  Component,
  Vue,
} from 'vue-property-decorator';
import { FetchPolicy } from 'apollo-client';
import Message from '@/components/mixins/Message.vue';
import VJsfForm from '@/components/commonComponents/VJsfForm.vue';
import { Model, Options, Schema } from '@/types/vjsf';

@Component({
  name: 'UsernamePassword',
  components: {
    VJsfForm,
  },
  data: () => ({
    model: { } as Model,
    schema: { type: 'object', properties: {} } as Schema,
    options: {} as Options,
    valid: null,
    metadata: undefined,
  }),
})
export default class UsernamePassword extends Vue {
  private token = this.$route.params.token;

  private loading = false;

  protected mounted(): void {
    this.fetchData();
  }

  private import() {
    if (this.token !== undefined) {
      return import('@/graphql/operations/update-forgotten-password-form');
    }
    return import('@/graphql/operations/forgot-password-form');
  }

  private fetchData(fetchPolicy: FetchPolicy = 'cache-first'): void {
    this.loading = true;

    const variables = {
      token: this.token,
    };

    this.import()
      .then(({ QUERY: query }) => this.$apollo.query({
        fetchPolicy,
        variables,
        query,
      }))
      .then((response) => {
        this.$data.model = response.data.form.model;
        this.$data.valid = response.data.form.valid;
        this.$data.options = response.data.form.options;
        this.$data.schema = response.data.form.schema;
        this.$data.metadata = response.data.form.metadata;
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private reset(): void {
    this.loading = true;

    const variables = {
      input: this.$data.model,
    };

    this.import()
      .then(({ MUTATION: mutation }) => this.$apollo.mutate({
        mutation,
        variables,
      }))
      .then((response) => {
        if (typeof response.data?.passwordForgotten?.message === 'string') {
          Message.success(response.data.passwordForgotten.message);
          this.$router.push({ name: 'login' });
        }
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
