
import {
  Component,
  Vue,
} from 'vue-property-decorator';
import {
  Model,
  Schema,
  Options,
} from '@/types/vjsf';
import useAuthStore from '@/stores/auth';
import { FetchPolicy } from 'apollo-client';
import VJsfForm from '@/components/commonComponents/VJsfForm.vue';
import Message from '@/components/mixins/Message.vue';

interface Tokens {
  token: string,
  refreshToken: string,
  expiresIn: number,
}

@Component({
  name: 'UsernamePasswordLogin',
  components: {
    VJsfForm,
  },
  data: () => ({
    model: { } as Model,
    schema: { type: 'object', properties: {} } as Schema,
    options: {} as Options,
    valid: null,
    metadata: undefined,
  }),
})
export default class UsernamePasswordLogin extends Vue {
  private authStore = useAuthStore();

  private loading = false;

  protected mounted(): void {
    this.fetchData();
  }

  private fetchData(fetchPolicy: FetchPolicy = 'cache-first'): void {
    this.loading = true;

    import('@/graphql/queries/login-form')
      .then(({ default: query }) => this.$apollo.query({
        fetchPolicy,
        query,
      }))
      .then((response) => {
        this.$data.model = response.data.loginForm.model;
        this.$data.valid = response.data.loginForm.valid;
        this.$data.options = response.data.loginForm.options;
        this.$data.schema = response.data.loginForm.schema;
        this.$data.metadata = response.data.loginForm.metadata;
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private login(): void {
    this.loading = true;

    this.getBearerToken()
      .then((tokens: Tokens) => this.storeToken(tokens))
      .then(() => {
        const { redirect } = this.$route.query;
        this.navigateTo((redirect ?? 'dashboard') as string);
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      })
      .finally(() => {
        this.$data.model.password = '';
        this.loading = false;
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw new Error(error);
      });
  }

  private getBearerToken(): Promise<Tokens> {
    const variables = {
      input: this.$data.model,
    };

    return new Promise((resolve, reject) => {
      import('@/graphql/mutations/login')
        // Make mutation
        .then(({ default: mutation }) => this.$apollo.mutate({ mutation, variables }))

        // Resolve with token or reject if there is no token given
        .then((response) => {
          const { token, refresh_token: refreshToken, expires_in: expiresIn } = response.data.login;
          if (token) {
            return resolve({ token, refreshToken, expiresIn });
          }
          return reject(new Error('No token found'));
        })
        .catch((error) => reject(error));
    });
  }

  /**
   * navigates to a specific page. At this moment the destinations are locked to a set of locations
   */
  private navigateTo(destination: string): void {
    const isNamedRoute = ['dashboard', 'login', 'register'].includes(destination);

    if (isNamedRoute) {
      this.$router.push({ name: destination });
    } else {
      this.$router.push(destination);
    }
  }

  private storeToken(tokens: Tokens): void {
    this.authStore.storeTokens(tokens.token, tokens.refreshToken, tokens.expiresIn);
  }
}
