
import {
  Component,
  Vue,
} from 'vue-property-decorator';
import VJsfForm from '@/components/commonComponents/VJsfForm.vue';
import {
  Model,
  Options,
  Schema,
  Valid,
} from '@/types/vjsf';
import { FetchPolicy } from 'apollo-client';
import Message from '@/components/mixins/Message.vue';

@Component({
  name: 'UsernamePassword',
  components: {
    VJsfForm,
  },
  data: () => ({
    model: { } as Model,
    schema: { type: 'object', properties: {} } as Schema,
    options: {} as Options,
    valid: null as Valid,
    metadata: undefined,
  }),
})
export default class UsernamePassword extends Vue {
  private loading = false;

  private notAvailableMessage = '';

  protected mounted(): void {
    this.fetchData('network-only');
  }

  private fetchData(fetchPolicy: FetchPolicy = 'cache-first'): void {
    this.loading = true;

    import('@/graphql/queries/register-form')
      .then(({ default: query }) => this.$apollo.query({
        fetchPolicy,
        query,
      }))
      .then((response) => {
        this.$data.model = response.data.form.model;
        this.$data.valid = response.data.form.valid;
        this.$data.options = response.data.form.options;
        this.notAvailableMessage = response.data.form.metadata.not_available_message;
        this.$data.schema = response.data.form.schema;
        this.$data.metadata = response.data.form.metadata;
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private register(): void {
    this.loading = true;
    // Make request to register user
    // If successful go to login page
    this.loading = false;
    Message.error(this.notAvailableMessage); // TODO TRANSLATIONS
  }
}
